export class Jersey {
  category: string;
  gender : string;
  name: string;
  display_Name : string;
  img_url : any; 
  hasCuts : boolean;
  cut_name :string;
  layoutType : string;
  thumbnail_url: string;
  sku_number: any;
  product_id: any;
  jersey_sku: string;
  shorts_sku: string;
 }

export class JerseyObj implements Jersey{
  constructor( public category : string, public gender : string, public name: string, public display_Name: string, public  img_url : any, public hasCuts : boolean,public cut_name: string, public layoutType : string, public thumbnail_url : string, public sku_number : any, public product_id: any, public jersey_sku : string, public shorts_sku : string){
  }
}

export interface Ifile
{   cutType : string;
    layoutType : string;
    img_url : any;
}

export class FileObj implements Ifile {
  constructor(public uniformType : string, public cutType : string, public layoutType : string, public img_url : any){
  }
}

export class UniformStyle
{
  category:string;
  files: Array<Ifile>;
  price:number;
  name:string;
  display_Name:string;
  gender:string;
  isEditable:string;
  createdAt: Date = new Date();
  progress:number;
  cuts : number;
  reversible : boolean;
  sku_number: any;
  jersey_sku: string;
  shorts_sku: string;
  thumbnail_url : string;
}

export class cuts {
  id: number;
  name: string;
}
export class gender {
  id: number;
  name: string;
}
export class sport{
  id : number;
  itemName : string;
}
export class reversible{
  id : number;
  name : string;
}
export class uniformtypes{
  id : number;
  name : string;
}
export class categoriesList{
  categoryName: string;
}


