import { Component, OnInit, TemplateRef, Output, EventEmitter } from '@angular/core';
// import { ColorPickerService } from 'angular2-color-picker';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';
// import { FirebaseApp } from 'angularfire2';
import { UniformStyle, Jersey } from '../models/upload'
import { styleService } from 'services/style.service';
import 'fabric';
import { AuthService } from 'services/auth.service';
import { MatTableDataSource } from "@angular/material";

declare const fabric: any;

@Component({
  selector: 'manage-styles',
  styleUrls: ['./manage-styles.component.css'],
  templateUrl: './manage-styles.component.html',
})

export class ManageStylesComponent implements OnInit {


  jerseyStyleList: any;
  selectedStyle: UniformStyle;
  private jerseyStylesobj: UniformStyle[] = [];
  private loading: boolean = false;
  private templates: any;
  catList = [];
  dataSource1:any;
  order_status:any;
  displayedColumns: string[] = ['SKU', 'Name', 'Category','Created','Modified', 'Actions'];
  constructor(
    private db: AngularFireDatabase,
    private router: Router, private _styleService: styleService,
    private auth: AuthService
  ) {
  }

  allTemplates = [];
  allTemplates1 = [];
  ngOnInit() {
    this.auth.currentUser();
    let email = localStorage.getItem('emailForSignIn');
    // let emailtocheck = localStorage.getItem('emailtocheck');
    // if (email != emailtocheck) {
    if (!email) {
      if (localStorage.getItem('userrole') == 'Admin')
        this.router.navigate(['/admin/login']);
      else
        this.router.navigate(['/customer/login']);
      localStorage.clear();
      // }
    }
    this.startLoadingSpinner();
    this.getAllCategory();
    this.db.list('/jersey-styles').snapshotChanges().subscribe(uniforms => {
      this.allTemplates = [];
      this.jerseyStyleList = uniforms;
      uniforms.forEach(element => {
        // if(element.payload.val().hasOwnProperty('files')){
          this.allTemplates.push(element.payload.val());
        // }
      });
      console.log("this.allTemplates",this.allTemplates);
    
      this.dataSource1 = new MatTableDataSource(this.allTemplates);
      this.loading = false;
    });
    document.getElementsByTagName('body')[0].setAttribute('style','overflow: auto');
  }

  getAllCategory(){
    this.db.list('/template-categories').snapshotChanges().subscribe(data => {
      this.catList = [];
      data.forEach(action => {
        const value = action.payload.val();
        console.log('value',value);
        // ShootingShirts
        if(value['name']=='Shooting Shirts'){
          let newCat = {
            key: action.key,
            name: 'ShootingShirts',
            nameNoSpace: value['nameNoSpace'],
            image:value['image']
          }
          this.catList.push(newCat);
        }else{
          let newCat = {
            key: action.key,
            name: value['name'],
            nameNoSpace: value['nameNoSpace'],
            image:value['image']
          }
          this.catList.push(newCat);
        }
      });
      console.log(this.catList);
    })
  }

  getSelectedStylesList() {
    this.jerseyStylesobj = [];
    // this.jerseyStylesobj = <UniformStyle[]> ;
    //Updates the list of Jerseys from backend.
    // this.jerseyStyleList.forEach(element => {
    //   let currentStyle: UniformStyle = new UniformStyle();

    //   let self = this;
    //   currentStyle.category = element.category;
    //   currentStyle.price = element.price;

    //   while (element.name.includes("-"))
    //     element.name = element.name.replace("-", " ");
    //   element.name = element.name.toUpperCase();

    //   currentStyle.name = element.name;
    //   currentStyle.gender = element.gender;
    //   currentStyle.cuts = self.getNumofCutTypes(element);
    //   this.jerseyStylesobj.push(currentStyle);
    // })
  }

  getNumofCutTypes(template: any): number {
    if (template.reversible)
      return template.files.length / 2;
    else if (template.files.length >= 1) {
      return template.files.length;
    }
  }

  onStyleSelect(style: UniformStyle) {
    console.log("SSTYLE", style);
    style.name = style.name.replace(/\s+/g, '-').toLowerCase();
    this._styleService.publishData(style);
    this.router.navigate([`admin/template-detail/${style.name}`]);
  }

  deleteStyle(uniformStyle) {
    let a = confirm("Are you sure you want to delete this Style?")
    if (a) {
      uniformStyle.name = uniformStyle.name.replace(/\s+/g, '-').toLowerCase();
      var dbRef = this.db.object(`/jersey-styles/${uniformStyle.name}/`);
      dbRef.remove().then(res => {
      });
  }
    
  }

  startLoadingSpinner() {
    this.loading = true;
    setTimeout(function () {
      // this.loading = false;
    }.bind(this), 1500);
  }
  applyFilter(ev) {
    let filterValue = ev.value;
    this.order_status = ev.value;
    this.dataSource1.filter = filterValue;
    this.allTemplates = this.dataSource1;
  }

}
