import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { AngularFireDatabase, AngularFireObject } from 'angularfire2/database';
import {Http, Headers,URLSearchParams,Response,RequestOptions} from '@angular/http';
import 'rxjs/add/operator/map';
import { HttpHeaders } from '@angular/common/http';
import { env } from 'process';

@Injectable()
export class SubmissionsService {
  submission: AngularFireObject<any>;
  // BASE_URL = process.env.NODE_API_URL;
  BASE_URL = "https://uniform-lab-node.herokuapp.com/";
  public data: any;
  constructor(private http:Http, private db:AngularFireDatabase){
    console.log("Submission service started");
  }

  //Login User
  login(credentials){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false});    
    return this.http.post(`${this.BASE_URL}auth/login`,credentials,{headers:headers});
  }
  
  //Register User
  registerUser(credentials){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false});    
    return this.http.post(`${this.BASE_URL}auth/register`,credentials,{headers:headers});
  }

  //Register Admin
  registerAdmin(credentials){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false});    
    return this.http.post(`${this.BASE_URL}auth/registerAdmin`,credentials,{headers:headers});
  }

  //get admin list
  getAllAdminList(){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'GET','Access-Control-Allow-Credentials':false});    
    return this.http.get(`${this.BASE_URL}auth/users/admin`,{headers:headers});
  }
  //delete admin users
  deletedAdminUser(credentials){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false});    
    return this.http.post(`${this.BASE_URL}auth/users/delete`,credentials,{headers:headers});
  }

  getSubmission(id) {
    console.log("Getting submission");
   console.log("the id is-- " + id);
  }

    // Fetches builder settings data, logo to populate in header bar WIP
    getCompanyInfo(){
      this.http.get(`${this.BASE_URL}companyInfo`).subscribe(res => {
      }, err => {
      })
    }

  // Save one submission JSON object
  saveSubmissionData(submissionObject){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false});    
    return this.http.post(`${this.BASE_URL}submission`, submissionObject,{headers:headers});
  }

  // Get one submission by ID
 getSubmissionByID(submissionId){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'GET','Access-Control-Allow-Credentials':false});    
    return this.http.get(`${this.BASE_URL}submission/${submissionId}`,{headers:headers});
  }

  // Get all submissions
  getAllSubmissions(){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'GET','Access-Control-Allow-Credentials':false});    
    return this.http.get(`${this.BASE_URL}submission/admin`,{headers:headers});
  }

   //  Get all customers submission
   getAllCustomers(customerMailId){
    return this.http.get(`${this.BASE_URL}submission/customer/${customerMailId}`);
  }

   //  Get all customers Design Request submission
   getAllDesignRequest(customerMailId){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'GET','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`}); 
    return this.http.get(`${this.BASE_URL}designrequest/customer/designs/${customerMailId}`,{headers:headers});
  }

  // Delete submission by ID
  deleteSubmissionByID(submissionId){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'GET','Access-Control-Allow-Credentials':false});    
    return this.http.delete(`${this.BASE_URL}submission/${submissionId}`,{headers:headers});
  }

  //Save As Quotes
  saveQuotes(quotesObject){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false, 'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.post(`${this.BASE_URL}quote`, quotesObject,{headers:headers});
  }

  //Get Customer Quotes
  getCustomersQuotes(){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'GET','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.get(`${this.BASE_URL}quote/customer`,{headers:headers});
  }

  //Get Admin Quotes
  getAdminQuotes(){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'GET','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.get(`${this.BASE_URL}quote/admin`,{headers:headers});
  }

  //Get Admin Quotes
  getDeTailsQuotes(id){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'GET','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.get(`${this.BASE_URL}quote/details/${id}`,{headers:headers});
  }

  updateQuotes(quotesUpdateObject){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false, 'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.post(`${this.BASE_URL}quote/update`, quotesUpdateObject,{headers:headers});
  }

  //Save New Design Request
  saveRequestDesign(newRequestObject){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.post(`${this.BASE_URL}designrequest`, newRequestObject,{headers:headers});
  }

  //Get Customer Design Request
  getCustomerRequestDesign(){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.get(`${this.BASE_URL}designrequest/customer`,{headers:headers});
  }

  //Get Customer Design Request
  getAdminRequestDesign(){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.get(`${this.BASE_URL}designrequest/admin`,{headers:headers});
  }

  getRequestDesignById(id){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.get(`${this.BASE_URL}designrequest/details/${id}`,{headers:headers});
  }

 
  //Add Edit Roster
  saveRoster(rosterData){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});  
    console.log()     
    return this.http.post(`${this.BASE_URL}order/roster`, rosterData,{headers:headers});
  }

  //get Addresses
  getAddresses(){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});     
    return this.http.get(`${this.BASE_URL}profile/address/customer`,{headers:headers});
  }

   //Add Address
  addAddress(addressData){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});
    return this.http.post(`${this.BASE_URL}profile/address/customer`, addressData,{headers:headers}); 
  }

   //Update Address
   updateAddress(addressData){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});
    return this.http.post(`${this.BASE_URL}profile/address/customer/update`, addressData,{headers:headers}); 
  }

  //Add PO
  addPO(poFormData){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});
    return this.http.post(`${this.BASE_URL}profile/add/poform`, poFormData,{headers:headers}); 
  }

  //add shipping or billing address
  addShippingOrBillingAddress(addressData, type){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});
    if(type == 'billing'){
      return this.http.post(`${this.BASE_URL}order/billing`, addressData,{headers:headers});
    }else if(type == 'shipping'){
      return this.http.post(`${this.BASE_URL}order/shipping`, addressData,{headers:headers});
    } 
  }

  //get notes by id
  getorderNote(id){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'GET','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
      return this.http.get(`${this.BASE_URL}order/notes/${id}`,{headers:headers});
    }

   //add order note by id
  addorderNote(noteToStore){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'GET','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
      return this.http.post(`${this.BASE_URL}order/notes`, noteToStore,{headers:headers});
    }

  //Save Orders
  saveOrder(orderObject){
      let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
      return this.http.post(`${this.BASE_URL}order`, orderObject,{headers:headers});
  }

  //Get Orders as customer
  getOrderList(){
  let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'GET','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.get(`${this.BASE_URL}order/customer`,{headers:headers});
  }
  //Get Orders as admin
  getOrderListAsAdmin(){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'GET','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
      return this.http.get(`${this.BASE_URL}order/admin`,{headers:headers});
    }

  //get orders by id
  getOrderListById(id){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'GET','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
      return this.http.get(`${this.BASE_URL}order/details/${id}`,{headers:headers});
    }

  //Get All Customer
  getAllCustomer(){
      let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
        return this.http.get(`${this.BASE_URL}users/admin`,{headers:headers});
      }
  
  //send message as admin on request design details
  sendMessageAsAdmin(message){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.post(`${this.BASE_URL}designrequest/sendmessage/admin`, message,{headers:headers});
  }

   //send message as customer on request design details
   sendMessageAsCustomer(message){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.post(`${this.BASE_URL}designrequest/sendmessage/customer`, message,{headers:headers});
  }

  //change order status by admin
  changeOrderStatus(orderId,status){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});   
    let data = {
      'orderId' : orderId,
      'status' : status
    } 
    return this.http.post(`${this.BASE_URL}order/status`, data,{headers:headers});
  }

  //delete Address
  deleteAddress(address){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});   
    
    return this.http.post(`${this.BASE_URL}profile/address/customer/delete`, address,{headers:headers});
  }

  //get order status
  getOrderStatus(){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});   
    
    return this.http.get(`${this.BASE_URL}status`,{headers:headers});
  }

  //send message as customer on request design details
  sendDesignUpload(message){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.post(`${this.BASE_URL}designrequest/designupload/admin`, message,{headers:headers});
  }

  //order payments
  orderPayments(orderId,stripeToken){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});   
    let data = {
      'orderId' : orderId,
      'stripeToken' : stripeToken
    } 
    return this.http.post(`${this.BASE_URL}order/payment`, data,{headers:headers});
  }

  //Approved design details
  approvedDesignAsCustomer(message){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.post(`${this.BASE_URL}designrequest/approve/customer`, message,{headers:headers});
  }

  //Rejected  design details
  rejectDesignAsCustomer(message){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.post(`${this.BASE_URL}designrequest/reject/customer`, message,{headers:headers});
  }

  //Save Cart data
  saveMyCart(cartObject){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.post(`${this.BASE_URL}cart/save`, cartObject,{headers:headers});
  }

  //Clear Cart for User
  destoryCart(cartObject){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.post(`${this.BASE_URL}cart/destroy`, cartObject,{headers:headers});
  }

  //Update Cart
  updateCart(cartObject){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.post(`${this.BASE_URL}cart/update`, cartObject,{headers:headers});
  }

  //get cart
  getCart(){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.get(`${this.BASE_URL}cart/customer`,{headers:headers});
  }

  //get builder Api 
  getConfiguration(){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.get(`${this.BASE_URL}configuration`,{headers:headers});
  }
  //Delete Quotes Admin by Id
  deletedQuotesById(id){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'GET','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.post(`${this.BASE_URL}quote/delete`,id,{headers:headers});
    
  }
  //get Design Request by customer
  getDesignRequestByCustomer(email){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.get(`${this.BASE_URL}designrequest/customer/${email}`,{headers:headers});
  }
  //get quotes by Quotes
  getQuotesById(email){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.get(`${this.BASE_URL}quote/customer/${email}`,{headers:headers});
  }

  getAddressesByEmail(email){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});     
    return this.http.get(`${this.BASE_URL}profile/address/customer/${email}`,{headers:headers});
  }

  getOrderById(email){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.get(`${this.BASE_URL}order/customer/${email}`,{headers:headers});
  }

  //Delete Design Admin by Id
  deletedDesignById(id){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'GET','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.post(`${this.BASE_URL}designrequest/delete`,id,{headers:headers});
  }

  //Update submission with quotes Id
  updateQuoteWithSubmission(subObject){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.post(`${this.BASE_URL}submission/updateQuote`, subObject,{headers:headers});
  }

  //Delete Address Admin by Id
  deleteAddressById(id){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'GET','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.post(`${this.BASE_URL}profile/address/delete`,id,{headers:headers});
  }

  //LinkExisting Quotes
  linkExistingQuotes(obj){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'GET','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.post(`${this.BASE_URL}designrequest/linkquote/customer`,obj,{headers:headers});
    
  }

  //update Settings
  UpdateAccountSetting(obj){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'GET','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.post(`${this.BASE_URL}configuration`,obj,{headers:headers});
  }

  //update Quotes Status
  changeQuoteStatus(obj){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'GET','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.post(`${this.BASE_URL}quote/update/status`,obj,{headers:headers});
  }

  //Create Quotes for Customer
  createQuoteCustomer(obj){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'GET','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.post(`${this.BASE_URL}quote/create/customer`,obj,{headers:headers});
  }

  getDashbordData(){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.get(`${this.BASE_URL}dashboard`,{headers:headers});
  }

  //Update Customer Information
  updateCustomerInformation(obj){
    let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'GET','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
    return this.http.post(`${this.BASE_URL}users/update`,obj,{headers:headers});
  }

  //Call Shopify Product
  // getAllShopifyProduct(){
  //   let headers = new Headers({ 'Accept': 'application/xml','Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
  //   return this.http.post(`${this.BASE_URL}shopify/products`,{headers:headers});
  // }
  // get All Collections
  // getAllCollection(){
  //   let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
  //   return this.http.get(`${this.BASE_URL}shopify/collections`,{headers:headers});
  // }

  // get All Collections
  // saveCheckCollection(obj){
  //   let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
  //   return this.http.post(`${this.BASE_URL}shopify/collections/save`,obj,{headers:headers});
  // }

  // change drop down for shopify products
  // filterByTitleShopify(obj){
  //   let headers = new Headers({ 'Accept': 'application/xml','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Credentials':false,'Authorization': `Bearer ${window.localStorage.getItem('token')}`});    
  //   return this.http.post(`${this.BASE_URL}shopify/products/collection`,obj,{headers:headers});
  // }
 
}
