import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { AngularFireDatabase } from 'angularfire2/database';
import { Subject } from 'rxjs/internal/Subject';
import { AdminNavComponent } from 'app/admin/admin-nav/admin-nav.component';
import { MatSidenav } from '@angular/material';
import { SubmissionsService } from 'services/submissions.service';


@Component({
  selector: 'customer-nav-bar',
  templateUrl: './customer-nav.component.html',
  styleUrls: ['./customer-nav.component.scss']
})
export class CustomerNavComponent implements OnInit {
  // @ViewChild('sidenav') sidenav: MatSidenav;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }
  isAdmin: boolean;
  role: any;
  email:any;
  isMenuOpen:boolean = true;
  builderConfiguration:any;

  constructor(public router: Router, private auth: AuthService, 
    private permissionsService: NgxPermissionsService, private db: AngularFireDatabase,private submissionService: SubmissionsService) {
      
    this.getMyLogo();
    this.email = window.localStorage.getItem('emailForSignIn')
  }

  ngOnInit(){
    this.submissionService.getConfiguration().subscribe(res => {
      this.builderConfiguration = res.json(); 
     });
  }

  goToCart(){
    this.router.navigateByUrl('/locker-room/my-cart');
  }

  collpaseSideBar(){
    // this.auth.menuSub.next(true);
    this.isMenuOpen = !this.isMenuOpen;
    if(!this.isMenuOpen){
      document.getElementById("menu").style.width = "60px";   
      document.getElementById("sideMenu").style.margin = "0px 0px 0px 60px";
    }else{
      document.getElementById("menu").style.width = "280px";   
      document.getElementById("sideMenu").style.margin = "0px 0px 0px 280px";
    }
  }

  myLogo = '';
  businessInfo = new Subject();
  businessInfoRef = null;
  getMyLogo() {
    this.db.object('/company-info/myInfo').snapshotChanges().subscribe(data => {
      let object = data.payload.val()
      this.myLogo = object['logoPath'];
      this.businessInfo.next(object);
      this.businessInfoRef = object;
    }, err => {
      this.myLogo = null;
    })
  }

}
