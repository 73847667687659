import { Component, OnInit } from '@angular/core';
// import { ColorPickerService } from 'angular2-color-picker';
import { NgForm } from '@angular/forms';
// import { AngularFireDatabase, FirebaseListObservable } from 'angularfire2/database';
// import { FirebaseApp } from 'angularfire2';
import { Jersey, Ifile, UniformStyle, sport, FileObj, gender, reversible, uniformtypes,categoriesList } from '../models/upload';
import { cuts } from '../../models/jersey';
import { UploadService } from '../../../services/upload.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import * as firebase from 'firebase';
import 'fabric';
import { ButtonsModule } from 'ngx-bootstrap';
import { AngularMultiSelect } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { ViewChild } from '@angular/core'
// import { AuthService } from 'services/auth.service';
import { Router } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';
import { ShopifyProductsComponent } from '../../dialogs/shopify-products/shopify-products.component';
import {MatDialogModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { LayerObj, Ilayer, ViewLayerData, IViewlayer, IPlayer, PlayerObj, IUniformSubmission, UniformSubmissionObject, Icolorselected, SelectedColorObj, ILayerColorOriginal, LayerColorOriginalObj, ITexttoCanvas, TexttoCanvasObj, IGraphic, GraphicObj, ShopifyProductObj, IShopifyProduct} from "../../models/jersey";
import { SubmissionsService } from 'services/submissions.service';
import { ShopifyService } from 'services/shopify.service';
import svgEditor from "svgedit";
import { location } from 'ngx-bootstrap/utils/facade/browser';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';
// import { svgEditor } from '/node_modules/svgedit/editor/svg-editor.html'
declare const fabric: any;
declare var Snap: any;
@Component({
	selector: 'add-add-new-product',
	templateUrl: './add-new-product.component.html',
	styleUrls: ['./add-new-product.component.css']
})


export class AddNewProductComponent implements OnInit {

	// @ViewChild(AngularMultiSelect, { static: false }) varietalDropdown: AngularMultiSelect;
	// @ViewChild('genderdropdownRef', { static: false }) genderdropdownRef: AngularMultiSelect;
	// @ViewChild('cutdropdownRef', { static: false }) cutdropdownRef: AngularMultiSelect;
	// @ViewChild('typedropdownRef', { static: false }) typedropdownRef: AngularMultiSelect;
	// @ViewChild('revcutdropdownRef', { static: false }) revcutdropdownRef: AngularMultiSelect;


	dropdownList = [];
	editTemplete4 = null;
	editTemplete3=null;
	snapSvg:any;
	dropdownList1 = [];
	varientArray = [];
	logoLocations = [];
	catList = [];
	selectedShopifyProduct: IShopifyProduct;
	public categoriesList = [];
	public shopifyList :any;
	private loading: boolean = false;
	private loading1: boolean = false;
	displayedColumns: string[] = ['SKU', 'Description', 'Price'];
	isId:any;
	constructor(
		private us: UploadService,
		// private auth: AuthService,
		public router: Router,
		private af: AngularFireDatabase,
		public dialog: MatDialog,
		private submissionService: SubmissionsService,
		private shopifyService: ShopifyService,
		private toastr: ToastrService
	) {
	}

	ngOnInit() {
		this.dropdownList = [
			{ "id": 1, "itemName": "Reversible" },
			{ "id": 2, "itemName": "Home and Away" },
			{ "id": 3, "itemName": "Game Day" }
		];
		this.dropdownList1 = [
			{ "id": 1, "categoryName": "Sport Uniform" },
			{ "id": 2, "categoryName": "Shooting Shirts" },
		];
		// this.dropdownList2 
        this.getAllCategory();
		// if(window.location.href.split('?shopifyId=')[1]){
			if(window.location.href.split('?shopifyId=')[1]){
				this.isId = window.location.href.split('?shopifyId=')[1];
				// this.getShopifyProduct();getByIdProducts
				this.getProductById(this.isId);
				this.loading1 = true;
				console.log("this.loading1",this.loading1);
			}else{
				this.loading1 = false;
				this.varientArray =[];
				console.log("this.loading1 else",this.loading1);
			}	
		// }
		
			
	}
	getProductById(id){
		this.loading = true;
		this.shopifyService.getByIdProducts(id).subscribe(data => {
			// this.shopifyList = [];
			this.shopifyList = data.json().productDetails;
			console.log("this.shopifyList",this.shopifyList);
			this.selectedShopifyProduct = new ShopifyProductObj (this.shopifyList.title,'','',this.shopifyList.images[0].src,this.shopifyList.variants ? this.shopifyList.variants : []);
			if(this.selectedShopifyProduct.productDetails){
			  this.varientArray = this.selectedShopifyProduct.productDetails;
			}
			this.loading = false;
		},error =>{
			this.loading = false;
		})

	}

	getAllCategory() {
		this.af.list('/template-categories').snapshotChanges().subscribe(data => {
		  this.catList = [];
		  data.forEach(action => {
			const value = action.payload.val();
			let newCat = {
			  key: action.key,
			  name: value['name'],
			  nameNoSpace: value['nameNoSpace'],
			  image: value['image'],
			  order: value['order']
			}
			this.catList.push(newCat);
		  });
		  this.catList.sort((a, b) => a.order - b.order);
		})
	  }

	getShopifyProduct(){
		let a = this;
		this.loading = true;
		this.shopifyService.getAllShopifyProduct().subscribe(data => {
		 this.shopifyList = [];
		   this.shopifyList = data.json();
		   this.shopifyList = this.shopifyList.filter(function( element ) {
			return element.id == a.isId;
		   })
		//    console.log("this.shopifyList ********!!!!!!!",this.shopifyList[0]);
		   this.selectedShopifyProduct = new ShopifyProductObj (this.shopifyList[0].title,'','',this.shopifyList[0].images[0].src,this.shopifyList[0].variants ? this.shopifyList[0].variants : []);
		   if(this.selectedShopifyProduct.productDetails){
             this.varientArray = this.selectedShopifyProduct.productDetails;
		   }
		//    console.log("this.selectedShopifyProduct",this.selectedShopifyProduct);
		   this.loading = false;
		});
	   }

	   removeThumbNails(){
		this.selectedShopifyProduct = new ShopifyProductObj (this.shopifyList[0].title,'','','',this.shopifyList[0].variants ? this.shopifyList[0].variants : []);
	   }
	   removeThumbNails1(){
		this.editTemplete = null;
	   }
	   fileBrowseHandler(files){
		if (files.length != 0) {
			let file = files[0];
			this.handleReaderLoaded(file)
	   }
	  }

		onFileDropped(ev){
		if (ev.target.files.length != 0) {
			let file = ev.target.files[0];
			this.handleReaderLoaded(file)
		}
		}

	   async handleReaderLoaded(file) {
		 this.toastr.warning("Logo upload in progress", '', { onActivateTick: true, timeOut: 10000, positionClass: 'toast-top-right' });
		 let storageRef = firebase.storage().ref();
		 let extendo = file.name.substring(file.name.indexOf("."));
		 const fileData = await storageRef.child(`${'/uploaded-logos'}/ADDTEMPLATETHUMBNAILS${extendo}`).put(file)
		   .then(snapshot => {
			 return snapshot.ref.getDownloadURL();
		   }).then(async (downloadURL) => {
			//  this.uploadedLogoPath = downloadURL;
			 this.selectedShopifyProduct = new ShopifyProductObj (this.shopifyList[0].title,'','',downloadURL,this.shopifyList[0].variants ? this.shopifyList[0].variants : []);
			 var pat = /^https?:\/\//i;
			 if (pat.test(downloadURL))
			 {
				 var request = new XMLHttpRequest();
				 var url = "https://firebasestorage.googleapis.com/v0/b/uniform-e640f.appspot.com/o/svg-design-images%2FReQuestImageSvg1597325059768?alt=media&token=ab98f7cd-2826-4d08-a651-a42a1548921a"
				 var txtFile = new XMLHttpRequest();
				 let svgHtmldata = null;
			 
				 txtFile.open("GET", downloadURL, true);
				 txtFile.onreadystatechange = function() {
					 if (txtFile.readyState === 4) {  // Makes sure the document is ready to parse.
					 if (txtFile.status === 200) {  // Makes sure it's found the file.
						 var allText = txtFile.responseText;
						 svgHtmldata = allText.toString();
					     console.log("svgHtmldata",svgHtmldata);
						//  document.getElementById("svgContainer").innerHTML = svgHtmldata;
					 }
					 }
				 }
				}
				 txtFile.send(null);
			 this.toastr.success('Logo sucessfully uploaded', '', { positionClass: 'toast-top-right' });
		   }).catch(error => {
			this.selectedShopifyProduct = new ShopifyProductObj (this.shopifyList[0].title,'','','',this.shopifyList[0].variants ? this.shopifyList[0].variants : []);
			 this.toastr.error("Faild to upload logo. Plase try again", '', { positionClass: 'toast-top-right', onActivateTick: true, timeOut: 10000 });
		   });
	 
	   }

	   fileBrowseHandler1(files){
		if (files.length != 0) {
			let file = files[0];
			this.handleReaderLoaded1(file)
	   }
	  }

		onFileDropped1(ev){
		if (ev.target.files.length != 0) {
			let file = ev.target.files[0];
			this.handleReaderLoaded1(file)
		}
		}
	
      editTemplete = null;
	   async handleReaderLoaded1(file) {
		 this.toastr.warning("Logo upload in progress", '', { onActivateTick: true, timeOut: 10000, positionClass: 'toast-top-right' });
		 let storageRef = firebase.storage().ref();
		 let extendo = file.name.substring(file.name.indexOf("."));
		 const fileData = await storageRef.child(`${'/uploaded-logos'}/templatesEditor${extendo}`).put(file)
		   .then(snapshot => {
			 return snapshot.ref.getDownloadURL();
		   }).then(async (downloadURL) => {
			 this.editTemplete = downloadURL;
			 var pat = /^https?:\/\//i;
			 if (pat.test(this.editTemplete))
			 {
				 var request = new XMLHttpRequest();
				 var url = "https://firebasestorage.googleapis.com/v0/b/uniform-e640f.appspot.com/o/svg-design-images%2FReQuestImageSvg1597325059768?alt=media&token=ab98f7cd-2826-4d08-a651-a42a1548921a"
				 var txtFile = new XMLHttpRequest();
				 let svgHtmldata = null;
			 
				 txtFile.open("GET", this.editTemplete, true);
				 txtFile.onreadystatechange = function() {
					 if (txtFile.readyState === 4) {  // Makes sure the document is ready to parse.
					 if (txtFile.status === 200) {  // Makes sure it's found the file.
						 var allText = txtFile.responseText;
						 svgHtmldata = allText.toString();
						 console.log("svgHtmldata",svgHtmldata);
						 document.getElementById("svgContainer").innerHTML = svgHtmldata;
					 }
					 }
				 }
				}
				 txtFile.send(null);
			 this.toastr.success('Logo sucessfully uploaded', '', { positionClass: 'toast-top-right' });
		   }).catch(error => {
			this.selectedShopifyProduct = new ShopifyProductObj (this.shopifyList[0].title,'','','',this.shopifyList[0].variants ? this.shopifyList[0].variants : []);
			 this.toastr.error("Faild to upload logo. Plase try again", '', { positionClass: 'toast-top-right', onActivateTick: true, timeOut: 10000 });
		   });
	 
	   }

	   addLogoLocation(){
		this.logoLocations.push(this.logoLocations.length + 1);
		this.addLogoLocation1();	
	   }


	   addLogoLocation1(){
		var location = document.getElementsByTagName("rect");
		this.snapSvg = Snap("#svg svg");
		var rect = this.snapSvg.rect(130,130,50,50);
		rect.attr({
			id: 'default-'+(this.logoLocations.length),
			style:'fill:transparent;stroke:black;stroke-width:2px'
		});
		var move = function(dx, dy, posx, posy) {
			this.attr({"x": this.ox + dx, "y": this.oy + dy});
			rect.attr({
				style:'fill:transparent;stroke:white;stroke-width:5px'
			});
		}
		var start = function(dx,dy,posx,posy) {
			this.ox = parseInt(this.attr("x"));
			this.oy = parseInt(this.attr("y"));
			rect.attr({
				style:'fill:transparent;stroke:white;stroke-width:5px'
			});
		}
		var stop = function() {	
			rect.attr({
				style:'fill:transparent;stroke:black;stroke-width:2px'
			});
		console.log('finished dragging');
		}
		rect.drag(move,start, stop );
			setTimeout(() => {
				for(var a=0;a<this.logoLocations.length;a++){
					var abc = <any>document.getElementById('location-'+this.logoLocations[a]);
					if(location[a].id){
						abc.value = location[a].id;
					}	
				}
			}, 1000);
		}
		saveLocations(){
		
			var location = document.getElementsByTagName("rect");
			if(location.length!=0){
				for(var a=0;a<location.length;a++){
						let id = <any>document.getElementById('location-'+this.logoLocations[a]);
						document.getElementById(location[a].id).setAttribute("id",id.value);
						document.getElementById(location[a].id).setAttribute("style",'fill:transparent');
				}
				}
}


fileBrowseHandler3(files){
	if (files.length != 0) {
		let file = files[0];
		this.handleReaderLoaded3(file)
   }
  }

	onFileDropped3(ev){
	if (ev.target.files.length != 0) {
		let file = ev.target.files[0];
		this.handleReaderLoaded3(file)
	}
	}

   async handleReaderLoaded3(file) {
	 this.toastr.warning("Logo upload in progress", '', { onActivateTick: true, timeOut: 10000, positionClass: 'toast-top-right' });
	 let storageRef = firebase.storage().ref();
	 let extendo = file.name.substring(file.name.indexOf("."));
	 const fileData = await storageRef.child(`${'/uploaded-logos3'}/templatesEditor${extendo}`).put(file)
	   .then(snapshot => {
		 return snapshot.ref.getDownloadURL();
	   }).then(async (downloadURL) => {
		 this.editTemplete3 = downloadURL;
		 console.log("this.editTemplete3",this.editTemplete3);
		//  var pat = /^https?:\/\//i;
		//  if (pat.test(this.editTemplete))
		//  {
		// 	 var request = new XMLHttpRequest();
		// 	 var url = "https://firebasestorage.googleapis.com/v0/b/uniform-e640f.appspot.com/o/svg-design-images%2FReQuestImageSvg1597325059768?alt=media&token=ab98f7cd-2826-4d08-a651-a42a1548921a"
		// 	 var txtFile = new XMLHttpRequest();
		// 	 let svgHtmldata = null;
		 
		// 	 txtFile.open("GET", this.editTemplete3, true);
		// 	 txtFile.onreadystatechange = function() {
		// 		 if (txtFile.readyState === 4) {  // Makes sure the document is ready to parse.
		// 		 if (txtFile.status === 200) {  // Makes sure it's found the file.
		// 			 var allText = txtFile.responseText;
		// 			 svgHtmldata = allText.toString();
		// 			 console.log("svgHtmldata",svgHtmldata);
		// 			 document.getElementById("svgContainer").innerHTML = svgHtmldata;
		// 		 }
		// 		 }
		// 	 }
		// 	}
			//  txtFile.send(null);
		 this.toastr.success('Logo sucessfully uploaded', '', { positionClass: 'toast-top-right' });
	   }).catch(error => {
		// this.selectedShopifyProduct = new ShopifyProductObj (this.shopifyList[0].title,'','','',this.shopifyList[0].variants ? this.shopifyList[0].variants : []);
		 this.toastr.error("Faild to upload logo. Plase try again", '', { positionClass: 'toast-top-right', onActivateTick: true, timeOut: 10000 });
	   });
 
   }

   fileBrowseHandler4(files){
	if (files.length != 0) {
		let file = files[0];
		this.handleReaderLoaded4(file)
   }
  }

	onFileDropped4(ev){
	if (ev.target.files.length != 0) {
		let file = ev.target.files[0];
		this.handleReaderLoaded4(file)
	}
	}


   async handleReaderLoaded4(file) {
	 this.toastr.warning("Logo upload in progress", '', { onActivateTick: true, timeOut: 10000, positionClass: 'toast-top-right' });
	 let storageRef = firebase.storage().ref();
	 let extendo = file.name.substring(file.name.indexOf("."));
	 const fileData = await storageRef.child(`${'/uploaded-logos4'}/templatesEditor${extendo}`).put(file)
	   .then(snapshot => {
		 return snapshot.ref.getDownloadURL();
	   }).then(async (downloadURL) => {
		 this.editTemplete4 = downloadURL;
		 var pat = /^https?:\/\//i;
		 if (pat.test(this.editTemplete4))
		 {
			 var request = new XMLHttpRequest();
			 var url = "https://firebasestorage.googleapis.com/v0/b/uniform-e640f.appspot.com/o/svg-design-images%2FReQuestImageSvg1597325059768?alt=media&token=ab98f7cd-2826-4d08-a651-a42a1548921a"
			 var txtFile = new XMLHttpRequest();
			 let svgHtmldata = null;
		 
			 txtFile.open("GET", this.editTemplete4, true);
			 txtFile.onreadystatechange = function() {
				 if (txtFile.readyState === 4) {  // Makes sure the document is ready to parse.
				 if (txtFile.status === 200) {  // Makes sure it's found the file.
					 var allText = txtFile.responseText;
					 svgHtmldata = allText.toString();
					 console.log("svgHtmldata",svgHtmldata);
					 document.getElementById("svgContainer2").innerHTML = svgHtmldata;
				 }
				 }
			 }
			}
			 txtFile.send(null);
		 this.toastr.success('Logo sucessfully uploaded', '', { positionClass: 'toast-top-right' });
	   }).catch(error => {
		// this.selectedShopifyProduct = new ShopifyProductObj (this.shopifyList[0].title,'','','',this.shopifyList[0].variants ? this.shopifyList[0].variants : []);
		 this.toastr.error("Faild to upload logo. Plase try again", '', { positionClass: 'toast-top-right', onActivateTick: true, timeOut: 10000 });
	   });
 
   }

   importFromShopify(){
	const dialogRef = this.dialog.open(ShopifyProductsComponent, {
		width: '650px'
	  });
  
	  dialogRef.afterClosed().subscribe(result => {
		  this.loading = true;
		this.dropdownList = [
			{ "id": 1, "itemName": "Reversible" },
			{ "id": 2, "itemName": "Home and Away" },
			{ "id": 3, "itemName": "Game Day" }
		];
		this.dropdownList1 = [
			{ "id": 1, "categoryName": "Sport Uniform" },
			{ "id": 2, "categoryName": "Shooting Shirts" },
		];
		// this.dropdownList2 
        this.getAllCategory();
		// if(window.location.href.split('?shopifyId=')[1]){
			if(window.location.href.split('?shopifyId=')[1]){
				this.isId = window.location.href.split('?shopifyId=')[1];
				this.getShopifyProduct();
				this.loading1 = true;
				console.log("this.loading1",this.loading1);
			}else{
				this.loading1 = false;
				this.varientArray =[];
				console.log("this.loading1 else",this.loading1);
			}	
		// }
	  });
}
}
