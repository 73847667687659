import { Component, OnInit, ViewChild } from '@angular/core';
// import {ColorPickerService} from 'angular2-color-picker';
import { NgForm } from '@angular/forms';
import { Routes, Router, ActivatedRoute } from '@angular/router';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { SubmissionsService } from '../../../services/submissions.service';

import 'fabric';
import { AuthService } from 'services/auth.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { element } from 'protractor';
import { ToastrService } from 'ngx-toastr';
declare const fabric: any;

@Component({
  selector: 'manage-colors',
  templateUrl: './manage-colors.component.html',
  styleUrls: ['./manage-colors.component.css']
})

export class ManageColorsComponent {
  private colorsList = [];
  private fontList = [];
  private showColorPane: boolean = true;
  private showFontPane: boolean = false;
  private editing: boolean = false;
  private fontediting: boolean = false;
  private loading: boolean = false;

  constructor(private subService: SubmissionsService,
    private af: AngularFireDatabase,
    private route: ActivatedRoute, private router: Router,
    private auth: AuthService,
    private toast: ToastrService
  ) {
  }

  ngOnInit() {
    this.auth.currentUser();
    let email = localStorage.getItem('emailForSignIn');
    // let emailtocheck = localStorage.getItem('emailtocheck');
    // if (email != emailtocheck) {
    if (!email) {
      localStorage.clear();
      if (localStorage.getItem('userrole') == 'Admin')
        this.router.navigate(['/admin/login']);
      else
        this.router.navigate(['/customer/login']);
      // }
    }
    this.startLoadingSpinner();
    this.getColor();
    // this.getFont();
  }
  getColor() {
    this.colorsList = [];
    this.af.list('/colors').snapshotChanges()
      .subscribe(data => {
        this.colorsList = [];
        data.forEach(action => {
          const value = action.payload.val();
          const $key = action.key;
          this.colorsList.push({ $key, ...value });
        });
        // let allcolors=this.colorsList;
        // this.colorsList=[];
        this.colorsList.sort((a, b) => a.order - b.order);
        this.loading = false;
      });


  }


  showColors() {
    this.showColorPane = true;
    this.showFontPane = false;
  }
  showFonts() {
    this.showFontPane = true;
    this.showColorPane = false;
    this.getFont();
  }

  newName = ''
  newCode = ''
  addNewColor(name, code) {
    if (!name && !code) return;
    var newColor = {
      colorName: name,
      colorCode: code.trim(),
      order: this.colorsList.length
    }
    this.af.list("/colors").push(newColor);
    this.getColor();
    this.newCode = ''
    this.newName = ''
    this.toast.show("Added a new color. ", "Success")
  }
  removeColor(color) {
    var a = confirm("Are you sure you want to delete this color?")
    if (a) {
      this.af.list("/colors").remove(color.$key).then(_ => {
        this.getColor();
      });
    }
  }
  toggleEditing(color) {
    if (color.editing) {
      color.editing = false;
    } else {
      color.editing = true;
    }
  }
  saveChanges(color) {
    color.editing = false;
    alert(JSON.stringify(color.$key));
    let key = color.$key;
    delete color.$key;
    let update = this.af.object(`/colors/${key}`).update(color);
    this.getColor();
  }



  // fonts
  getFont() {
    this.fontList = [];
    this.af.list('/fonts').snapshotChanges()
      .subscribe(data => {
        this.fontList = [];
        data.forEach(action => {
          const value = action.payload.val();
          const $key = action.key;
          this.fontList.push({ $key, ...value });
        });
        this.loading = false;
      });
  }
  toggleFontEditing(font) {
    if (font.fontediting) {
      font.fontediting = false;
    } else {
      font.fontediting = true;
    }
  }

  addNewFonts(name, code) {
    if (!name && !code) return;
    var newFont = {
      displayname: name.trim(),
      fontname: code.trim()
    }
    this.af.list("/fonts").push(newFont);
    this.getFont();
  }
  removeFonts(font) {
    var a = confirm("Are you sure you want to delete this font?")
    if (a) {
      this.af.list("/fonts").remove(font.$key).then(_ => {
        this.getFont();
      });
    }
  }

  saveFontChanges(font) {


    font.fontediting = false;
    alert(JSON.stringify(font.$key));
    let key = font.$key;
    delete font.$key;
    let update = this.af.object(`/fonts/${key}`).update(font);
    // this.getFont();
  }

  startLoadingSpinner() {

    this.loading = true;
    setTimeout(function () {
    }.bind(this), 1500);
  }


  removeItem(item: any, list: any[]): void {
    list.splice(list.indexOf(item), 1);
  }


  drop(event: CdkDragDrop<string[]>) {
    let color = this.colorsList[event.previousIndex];
    moveItemInArray(this.colorsList, event.previousIndex, event.currentIndex);
    if (event.previousIndex != event.currentIndex) {
      this.colorsList.forEach((color, index) => {
        color.order = index;
        let key = color.$key;
        delete color.$key;
        let update = this.af.object(`/colors/${key}`).update(color);
      });
    }
  }

  saveChangesWithOrder(color, order) {
    color.editing = false;
    alert(JSON.stringify(color.$key));
    let key = color.$key;
    delete color.$key;
    let update = this.af.object(`/colors/${key}`).update(color);
    this.getColor();
  }
}
