import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import * as firebase from 'firebase/app';
import { MatTableDataSource } from "@angular/material";
import { Router } from '@angular/router';
import { SubmissionsService } from 'services/submissions.service';

@Component({
  selector: 'app-my-quotes',
  templateUrl: './my-quotes.component.html',
  styleUrls: ['./my-quotes.component.css']
})



export class AdminMyQuotesComponent implements OnInit {
  orderList = [];
  orderList1 = []
  private loading:boolean = false;
  displayedColumns: string[] = ['Quotes', 'Date', 'Total Cost','Status','Actions'];
  dataSource : MatTableDataSource<{orderName : string, orderNumber : number, orderTotal  :  number, dateCreated : Date, products:any}>;
  constructor(
    private toastr: ToastrService,
    private af: AngularFireDatabase,
    private db: AngularFireDatabase,
    private router: Router,
    private submissionService: SubmissionsService,
  ) { }

  companyLogo$: AngularFireList<any[]>;

  ngOnInit() {
    this.getAllQuotesData(); 

  }

  getAllQuotesData(){
    this.submissionService.getAdminQuotes().subscribe(res => {
     this.orderList = [];
     this.orderList = JSON.parse((<any>res)._body);
     this.orderList = this.orderList.filter(function( element ) {
      return element.data !== undefined;
     });

     setTimeout(() => {
      this.orderList = this.orderList.filter(function( element ) {
        return element.data.quotesName !== undefined;
       });
       console.log("this.orderList !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",this.orderList);
       
     }, 1000);
     this.orderList = this.orderList.sort((a, b) => Date.parse(b.data.date) - Date.parse(a.data.date));
     this.orderList1 = this.orderList;
    //   this.router.navigate(['/locker-room/order-details'],{ queryParams: { orderId: newOrder.orderNumber} })
    });
  }

  goToQuotesDetails(element){
    // alert("Order details see goToOrderDetails function call")
    // TODO Vishal pass order id as param 
    this.router.navigate(['/admin/my-quotes-details'],{ queryParams: { quotesId: element._id} })
  }

  onOpenMenu(menu: any): void {
  }

  actionOrder(action,element){
  //  if(action=='view'){
  //   this.router.navigate(['/locker-room/order-details'],{ queryParams: { orderId: element.orderNumber} })
  //  }else if(action=='delete'){
  //   var a = confirm("Are you sure you want to remove this Order?")
  //   if (a) {
  //     this.af.list("/order").remove(element.$key).then(_ => {
  //     });
  //   }
  //  }
  }

  deleteQuotesById(id){
    this.loading = true;
    var obj = {
      quoteId:id
    }
     let a = confirm("Are you sure you want to delete this Quote?")
    if (a) {
      this.loading = true;
      this.submissionService.deletedQuotesById(obj).subscribe(res => {
        this.toastr.success(res.json().message, '', { positionClass: 'toast-top-right' });
        this.getAllQuotesData();
        setTimeout(() => {
          this.loading = false;
        }, 2000);
       });
  }
}

sortByStatus(status){
  // this.getAllQuotesData();
  this.orderList=this.orderList1;
if(status=='ALL'){
  this.getAllQuotesData();
}else{
  this.orderList = this.orderList.filter(function( element ) {
    return element.status == status;
   });
}
}



}
