export class cuts {
  id: number;
  name: string;
}

// Used to Store Layer Data in Style Details page and Editor for Apply Color
 export interface Ilayer{
  layerID: string;
  displayName: string;
  IsEditable: boolean;
}

export class LayerObj implements Ilayer {
  constructor (public layerID: string, public displayName: string, public IsEditable: boolean) {
  }
}

// Used to store in fire base
export interface IViewlayer{
 layerdata : Ilayer[];
 CutType:String;
}

export class ViewLayerData implements IViewlayer{
  constructor(public layerdata : Ilayer[], public CutType:String){
  }
  }

//Used for Add Player and saved in firebase with IUniformSubmission
export interface IPlayer{
  name: string;
  topSize: string;
  bottomSize: string;
  number: number;
}

export class PlayerObj implements IPlayer{
  constructor(public name : string, public topSize: string, public bottomSize:string, public number:number){
  }
  }

  // export interface  Ilogo{
  //   logo_url: string;
  //   locations: any[];
  // }

//For Uniform submission - to store in Firebase
export interface IUniformSubmission {
  name: string;
  phone: string;
  email: string;
  organization:string;
  notes:string;
  Players : IPlayer[];
  base64_OutSide : any;
  base64_InSide : any;
  json_OutSide: any;
  json_InSide: any;
  LayerColors_OutSide : Icolorselected[];
  LayerColors_InSide : Icolorselected[];
  svgFileHtml: any;
  insideFileHTML: any;
  JerseyObj: any;
  logo: any;
  uniformType: any;
  availableLogoLocations: any;
  svgFile:string;
  pngFile:string;
  attachmentURL:any,
  date: any,
  finalDesignSVGUrl: any,
  finalDesignsSVGUrl: any

}

  export class UniformSubmissionObject implements IUniformSubmission{
    constructor(public svgFile:string,public pngFile:string,public name : string, public phone:string, public email: string, public organization:string, public notes:string, public Players : IPlayer[], public base64_OutSide :any, public base64_InSide :any, public  json_OutSide: any, public  json_InSide: any, public LayerColors_OutSide : Icolorselected[], public LayerColors_InSide : Icolorselected[], public date: any, public svgFileHtml: any, public insideFileHTML: any, public JerseyObj: any, public logo: any, public uniformType: any, public availableLogoLocations: any,public finalDesignSVGUrl: any,public finalDesignsSVGUrl: any,public attachmentURL: any){
    }
  }


  //Store Colorcode and color name with selected for LAyer  to store data with UniformSubmissionObject : LayerColors
  export interface  Icolorselected{
      layer: string;
      colorCode: any;
      colorName: string;
      fontStroke:string;
      fontStrokeColorName:string;
      strokeWidth:string;
      fontType: string;
      fontSize: string;
      text: string;
  }

  export class SelectedColorObj implements Icolorselected {
    constructor ( public layer: string, public colorCode: any,public colorName: string,public fontStroke: string,public strokeWidth: string,public fontStrokeColorName : string, public fontType : string, public fontSize : string, public text : string) {
    }
  }

  //Store Orignal Color of every Layer for verification on Hover - remove to white / Actual color of Layer if notselected any Color.
  export interface ILayerColorOriginal{
    layer: string;
    colorCode: any;
    colorName:string;
  }

  export class LayerColorOriginalObj implements ILayerColorOriginal {
  constructor ( public layer: string, public colorCode: any, public colorName : string) {
  }
  }

  //Interface and class for Apply Text with color, style and font size in Graphic Options
  export interface ITexttoCanvas{
    teamorplyaer : string;
    field: string;
    value : any;
  }

  export class TexttoCanvasObj implements ITexttoCanvas{
    constructor(public teamorplyaer : string, public field : string, public value : any){
    }
  }


  //Interface and class for holding Data to Create Movable object of Team Name and Player Name with color, style and font size in Graphic Options
  export interface ITextprops{
    Name : string;
    font_Size : number;
    colorCode : string;
    font_Type : string;
    font_stroke : string;
    top:number;
    left:number;
  }

  export class TextpropsObj implements ITextprops{
    constructor(public Name : string,public font_Size : number, public colorCode : string, public font_Type : string,public font_stroke:string,public top : number,public left : number){
    }
  }
 
  //Interface and Class for Holding All Fonts details 
  export interface IFont{
    fontname : string;
    displayname :string;
  }

  export class FontObj implements IFont{
    constructor(public displayname: string, public fontname :string){
    }
  }

  //Interface and Class for Holding Graphic componet Values for Toggle 
export interface IGraphic{
Player_Name :string;
Team_Name :string;
Player_FontType :string ;
Team_FontType :string ;
Player_FontSize : number;
Team_FontSize : number;
Numbers_FontType : string;
Front_FontSize : number;
Back_FontSize : number;
Team_FontStroke : string;
Player_FontStroke : string;
Numbers_FontStroke : string;
Team_Left : number;
Team_Top : number;
Player_Left : number;
Player_Top : number;
Front_Left : number;
Front_Top : number;
Back_Left : number;
Back_Top : number;
Front_Number :string;
Back_Number:string;

}

export class GraphicObj implements IGraphic{
  constructor(public Player_Name: string, public Team_Name :string, public Player_FontType : string, public Team_FontType : string, public Player_FontSize: number, 
    public Team_FontSize : number, public Numbers_FontType : string, public Front_FontSize : number, public Back_FontSize : number, public Team_FontStroke : string,
     public Player_FontStroke : string, public Numbers_FontStroke : string, public Team_Left : number, public Team_Top : number,
     public Player_Left : number, public Player_Top : number, public Front_Left : number, public Front_Top : number, public Back_Left : number,public Back_Top : number,
    public Front_Number:string,public Back_Number:string){
  }
}

//interface and classes for  shopify products
export interface IShopifyProduct{
  productName :string;
  categoryName :string;
  uniformStyle :string ;
  thumbnailImage:string ;
  productDetails: any;
  }

  export class ShopifyProductObj implements IShopifyProduct{
    constructor(public productName: string, public categoryName :string, public uniformStyle : string, public thumbnailImage : string, public productDetails: any){
    }
  }